import './NosVoitures.css';

import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderIcon from '@mui/icons-material/Folder';
import { Button } from '@mui/material';
import CarCard from "../../components/CarCard/CarCard";

const carFiles = ['businessVan','luxuryCar','businessCar',];
const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));

const carsRange = [
  {
    imgUrl: '/ClasseV.png',
    carType: 'BUSINESS VAN',
    brand: 'Mercedes Classe V',
    passengerNb: '4',
    LuggageNb: '4',
    linkUrl: '',
    presentation: 'La Classe V captive par son confort digne de Mercedes-Benz : de l\'habitabilité exceptionnelle à la qualité incomparable de ses matériaux et finitions, en passant par son concept de commande intuitif et l\'éclairage d\'ambiance. Tout a été fait pour rendre le séjour dans la Classe V le plus agréable possible.',
    informations:[
      'Espace supplémentaire', 'sièges individuels à l\'arrière', 'Banquette 3 places confort pour le siège extérieur rabattable', 'Toit ouvrant'
    ]
  },

  {
    imgUrl: '/Maybach.png',
    carType: 'LUXURY CAR',
    brand: 'Mercedes Maybach',
    passengerNb: '3',
    LuggageNb: '2',
    linkUrl: '',
    presentation: "Plongez dans l'univers du luxe absolu avec la Mercedes Maybach, une expérience de conduite inoubliable qui éveillera tous vos sens. Laissez-vous séduire par ses lignes élégantes et son intérieur somptueux, où le confort et le raffinement se rejoignent. Avec des sièges en cuir d'une douceur exquise et une suspension haut de gamme, chaque trajet à bord de la Mercedes Maybach devient un moment privilégié. ",
    informations:[
      'Espace supplémentaire', 'sièges individuels à l\'arrière', 'Banquette 3 places confort pour le siège extérieur rabattable', 'Toit ouvrant'
    ]
  },
  {
    imgUrl: '/ClasseE.png',
    carType: 'BUSINESS CAR',
    brand: 'Mercedes Classe E',
    passengerNb: '3',
    LuggageNb: '2',
    linkUrl: '',
    presentation: "Découvrez l'élégance moderne de la Mercedes Classe E, une voiture qui incarne le parfait équilibre entre performances, confort et style. Avec ses lignes dynamiques et sa calandre distinctive, cette berline de luxe offre une expérience de conduite exceptionnelle. L'intérieur raffiné de la Classe E, avec ses matériaux haut de gamme et ses équipements technologiques de pointe, crée un environnement accueillant et sophistiqué. Louez dès maintenant la Mercedes Classe E et profitez d'un voyage tout en élégance, où chaque trajet devient une expérience mémorable, alliant puissance, confort et prestige.",
    informations:[
      'Espace supplémentaire', 'sièges individuels à l\'arrière', 'Banquette 3 places confort pour le siège extérieur rabattable', 'Toit ouvrant'
    ]
  },
];


/*const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
})); */

const NosVoitures = () => {
  const [carDataMap, setCarDataMap] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Importez dynamiquement les fichiers JSON
    const dataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
    setCarDataMap(dataMap);
  }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant


  /*const [expanded, setExpanded] = React.useState(false); */

  /* const handleExpandClick = () => {
    setExpanded(!expanded);
  }; */
  

  return (
    <div className="NosVoituresSection">
      <div className="NosVoituresHeader">
        <h1>Retrouvez toutes les informations disponibles sur nos modèles de voitures</h1>
        <p>Découvrez notre flotte de véhicules haut de gamme, conçue pour offrir une expérience de transport exceptionnelle. Chez Tarif-VTC, nous nous engageons à vous fournir des voitures élégantes, confortables et parfaitement entretenues. Que vous recherchiez une berline luxueuse pour une soirée spéciale, un véhicule spacieux pour vos déplacements en groupe ou une voiture hybride pour une approche écologique, nous avons la solution idéale pour vous. Chacun de nos véhicules est équipé des dernières technologies et garantit une conduite en toute sécurité. Faites confiance à notre flotte de voitures de qualité supérieure pour des trajets inoubliables.</p>
      </div>

      <section className="CarsRangeSection">
              <div className="CarsRangeSectionContent">
                <h2>Nos Vehicules</h2>
                <div className="CarsRangeCardList">
                  {carDataMap.map((carData, index) => (
                    <CarCard imgUrl={carData.img} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                  ))}
                </div>
              </div>
            </section>

    {/*  <div className="NosVoituresContainer">
        {carsRange.map((item, index) => (
          <div className="NosVoituresCard" key={item.brand + index}>

        <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: '#1876d1' }}>
              <FolderIcon />
            </Avatar>
          }

          title={item.carType}
          subheader={item.brand}
        />
        <CardMedia
          component="img"
          height="194"
          image={item.imgUrl}
          alt="Paella dish"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {item.presentation}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
      
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {carsRange.map((item, index) => (
              <Typography paragraph key={item.carType + index}>{item.informations[index]}</Typography>
            ))}
          </CardContent>
        </Collapse>
      </Card>
      </div>
      ))}
    </div> */}
  </div>
  );
};
export default NosVoitures;