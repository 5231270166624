import BusinessVan from "../../data/businessVan.json";
import LuxuryCar from "../../data/luxuryCar.json";
import BusinessCar from "../../data/businessCar.json";

import '../NosVoitures/VoituresInformations.css';

import React, { useEffect, useState } from 'react'
import { Outlet, Link, useParams  } from "react-router-dom";

import Button from '@mui/joy/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';

const carDataMap = {
    businessvan: BusinessVan,
    luxurycar: LuxuryCar,
    businesscar: BusinessCar,
  };

const Taxi6Places= ({}) => {
    //const { carName } = useParams();
    const carName = "businessvan";
    const carData = carDataMap[carName];

    const otherCars = Object.entries(carDataMap).filter(([key, car]) => car.name !== carData.name);

    useEffect(() => {
      window.scrollTo(0, 0);
    })
  
    if (!carData) {
      // Gérez le cas où les données de la voiture ne sont pas trouvées
      return <div>Voiture non trouvée</div>;
    }

    return (
        <div className="car_section">
          <div className="nos_voitures_section_contentHead" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${carData.wallpaper})`}}>
            <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
            <h1>ROISSY charles de gaulle Paris</h1>
          </div>

          <section className='head_title_section'>
            <div className='head_title_content'>
              <h3> Service <strong> TARIF VTC Roissy</strong></h3>
              <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
              <div className='social_media_icons'>
                <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
              </div>
            </div>
          </section>    

          <section>
            <div className="partie">

            <p>
              Un voyage de prévue prochainement à Paris, vous avez besoin <strong>chauffeur vtc de Roissy charles de gaulle</strong>, programmer votre transfert aéroport cdg en reservant votre moyen de transport fiable dès maintenant.<br/>
              <strong> TARIF VTC ROISSY Cdg</strong> vous permet de determiner le prix de votre trajet en avance, au départ de cdg vers Paris, Orly, Beauvais, ou toute autre destination en Ile de France.
              Tarif vtc Roissy vous facilite la mise en relation avec des chauffeurs professionnels diplômés, pour vous conduire en tout sécurité durant vos trajets personnels ou professionnels depuis l'aéroport roissy charles de gaulle.<br/>
              Votre véhicule de tourisme avec chauffeur peut être réservé à l’heure pour vous garantir une tranquillité dans vos trajets, vous pouvez avoir votre chauffeur à disposition pour d’éventuelles arrêt.
              Vous pouvez également determiner le <strong>tarif d'un vtc pour un trajet roissy charles de gaulle longue distance</strong>
              TARIF VTC le spécialiste des voyages long distances est disponible à partir de la région parisienne vers l’ensemble du territoire, il vous reste plus qu’à réservé !!!
            </p>

            <div className="inside">

              <h3 class="color3 mb upper" >Service <strong> TARIF VTC Roissy</strong> </h3>
              <p>
                TARIF VTC Roissy comprends Trois type de prix adapté et qui répond à tous vos besoins de deplacement en <strong>Voiture de transport avec chauffeur depuis Roissy Charles de Gaulle</strong> selon votre budget.<br/>
                <strong>Le TARIF Business</strong>: pouvant accueillir 3 passagers, peut être réservé pour des course simple, des trajets aller-retour ou à l’heure, ce qui vous permettra une grande flexibilité.<br/>
                Vous avez besoin de vous rendre à l’aéroport ou dans une gare ?
                Vous avez de nombreuses valises ?
                Alors pas de soucis ! nous avons la solution !<br/>
                <strong>Le TARIF Business Van / Minibus</strong> prévu pour voyager en groupe, pouvant accueillir 6 passagers et avec une grande capacité de bagages mais surtout ideal pour des circuits touristiques.<br/>
                <strong> Le TARIF First Class</strong> a été conçu pour vous rendre à des rendez-vous d’affaire les plus prestigieux de la capitale.<br/>
                TARIF-VTC mets un point d’honneur sur la qualité de service, le professionnalisme et la ponctualité de nos chuffeurs vtc qu’importe le service choisi.
              </p>
            </div>

            </div>

            <div className="partie">

            <div className="inside">


            <h3 class="color3 mb upper" ><strong>TARIF VTC aéroport Cdg </strong> </h3>

            <p>
              Avec TARIF-VTC vous aller pouvoir être transporter en toute sérénité à travers les routes d’ile de France et de France dans des berlines de haute gamme en toute sécurité.
              Il vous suffit de réserver vos trajets ou transferts vers les gares et aeroports tout simplement sur notre site internet wwww.tarif-vtc.fr ou en nous contactent au 06.50.24.09.98 pour réserver un de nos chauffeurs vtc privé pour un deplacements en toute securité, en quelques secondes avec un payement sécurisé.
              Chez tarif vtc, nous croyons aux valeurs de transparence et d’honnête, c’est pourquoi lorsque vous réservé notre servive de transport avec nos chauffeurs, le prix final communiqué est celui que vous payerez.
              Aucun frais additionnel ne sera ajouté lors de votre réservation, vous pouvez donc calculer précisément vos frais de déplacement a l’avance (ce qui est très pratique lorsque vous voyager).
              Peu importe l’option que vous choisit, en réservant sur www.tarif-vtc.fr vous aurez le meilleur partenaire de vos trajets 
            </p>
            </div>

            <div className="inside">


            <h3 class="color3 mb upper" ><strong>VTC POUR ROISSY</strong> TARIF VTC PARIS ROISSY</h3>
            <p>
              Nous mettons notre expérience et notre savoir-faire au service de nos clients. En effet, nous mettons à votre disposition une
              large gamme de Prix de différentes modeles afin de répondre à tous les goûts et à tous les budgets.
              Que ça soit pour vos courses professionnels ou personnels, faites appel à notre service et déplacez-vous en toute liberté.
              Reserver votre trajet longue distance, des Transferts longue distance vers toute la France , au départ ou a l'arrivé de la région <strong> de Bretagne ou de Paris </strong>et des villes a proximité ( Rouen, Nantes, Bordeaux, Amiens, Rouen, Normandie, Reims, Lyon, Nice, Le Mans)<br/>
              Vous avez besoin d'un taxi privé pour un déplacement long trajet ou un circuit en France?
              Une réservation ? un départ a l’immédiat ? <br/>
            </p>
            </div>

            </div>

            <div className="partie">

            <div className="inside">


            <h3 class="color3 mb upper">Tarif <strong>CHAUFFEUR PRIVE </strong>ROISSY </h3>
          <p> 
          TARIF VTC accompagne sa clientèle dans l’organisation de tous types d’évènements, tels que : la Fashion Week, les dîners de gala et bien d’autres. Pour ce faire, nous proposons un service de transports haut de gamme, discret et professionnel pour des soirées réussies.</p>

          <p>Profitez d’un service d’accueil personnalisé à votre entreprise,  de transfert et de mise à disposition toute la journée et toute la nuit avec nos chauffeurs expérimentés.</p>

          <p> Qu’il s’agisse d’un évènement public ou privé, culturel ou sportif, TARIF-VTC assure le transport pour les personnes qui vous sont importants et met à leur service des chauffeurs privés, sérieux et expérimentés. </p>

          <li>Mise à disposition à l'heure, ou à la journée </li>
          <li>Garde du corps (APR) en option</li>
          <li>Chauffeurs expérimentés bilingues </li>
          <li>accueil personnalisé</li>
            </div>

          </div>

          </section>

          

            <div className="car_details">
              <div className="car_details_secondary">
                  <div className="car_details_travel">
                      <h3>TRAJET</h3>
                        <p><GroupIcon sx={{ fontSize: 30 }}/> {carData.person} Personnes</p>
                        <p><LuggageIcon sx={{ fontSize: 30 }}/> {carData.luggage} Baggages</p>
                        {carData.additional_luggage ? <p><LuggageIcon sx={{ fontSize: 30 }}/> {carData.additional_luggage} Petites valises</p> : ''}
                  </div>
                  <div className="car_details_left">
                      <h3>ÉQUIPEMENTS</h3>
                      <ul>
                          {carData.equipements.map((equipement, index) => (
                              <li key={index}><ListItemIcon> <ArrowRightIcon /> </ListItemIcon> {equipement}</li>
                          ))}
                      </ul>
                  </div>
                  <div className="car_details_right">
                      <h3>CARACTÉRISTIQUES TECHNIQUES</h3>
                      <ul>
                          {carData.technical_caract.map((technical_caract, index) => (
                              <li key={index}><ListItemIcon> <ArrowRightIcon /> </ListItemIcon> {technical_caract}</li>
                              ))}
                      </ul>
                  </div>
              </div>
            </div>


            <div className="car_gallery">
                {carData.gallery.map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index}`} width={350} />
                ))}
            </div>

            <div className="other_car">
              <p>Retrouvez nos voitures : </p>
              <div className="other_car_button_list">
                {otherCars.map((car, index) => (
                  <Link to={car[1].linkUrl} key={index}><Button variant="soft"> {car[1].name} </Button></Link>
                ))}
                </div>
            </div>

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
            
        </div>
    );
};
export default Taxi6Places;
