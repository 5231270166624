// components/CarCard.jsx

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

import { Outlet, Link } from "react-router-dom";

import './CarCard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faSuitcase } from '@fortawesome/free-solid-svg-icons'

const CarCard = ({imgUrl='', alt='', carType='', brand='', passengerNb='', LuggageNb='', addLuggage='', linkUrl=''}) => {
  return (
    <>
        <div className="customCarRangeCard">
            <Card sx={{ width: 400 }} className="Card">
                <CardMedia
                    component="img"
                    height="160"
                    image={imgUrl}
                    alt={alt}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" className="CardType">
                        {carType}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div" className="CardBrand">
                        {brand}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="passengerNb">
                        <FontAwesomeIcon icon={faUsers} className="cardIconCustom"/>
                        {passengerNb} passagers
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="LuggageNb">
                        <FontAwesomeIcon icon={faSuitcase} className="cardIconCustom"/>
                        {LuggageNb} bagages
                    </Typography>
                    {addLuggage ? 
                        <Typography variant="body2" color="text.secondary" className="LuggageNb">
                            <FontAwesomeIcon icon={faSuitcase} className="cardIconCustom"/>
                            {addLuggage} Petites valises
                        </Typography>
                    : <Typography variant="body2" color="text.secondary" className="LuggageNb"> - </Typography>}
                </CardContent>
                <CardActions className="actionButton">
                    <Link to={linkUrl}>
                        <Button size="small" color="primary">
                            En savoir plus
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
    </>
  );
};
export default CarCard;