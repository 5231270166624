import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';

import "./ReservationContainer.css"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const apiUrl = process.env.NODE_ENV === 'production' 
? process.env.REACT_APP_API_URL_PRODUCTION 
: process.env.REACT_APP_API_URL_LOCAL;

function ReservationContainer() {
  const query = useQuery();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    id_travel: '',
    type_travel: 'simple journey',
    adresse_start: query.get('adresse_start') || '',
    zipcode_start: '',
    city_start: '', 
    country_start: '',
    adresse_end: query.get('adresse_end') || '',
    zipcode_end: '',
    city_end: '', 
    country_end: '',
    departure: '',
    arrival: '',
    duration: '3',
    date: query.get('date') || new Date().toISOString().split('T')[0],
    departure_time: query.get('departure_time') || '11:00',
    type_car: 'eco',
    nb_passenger: 1,
    nb_suitcase: 0,
    travelCalculateData:{
        price: 0,
        devise: '€',
        distance: '',
        estimated_time: '',
    },
    options: {
        price_option: 0,
        babySeat: 0,
        nb_booster_seat: 0,
        tablet_home: false,
    },
    passengerDetails: {
        gender: 'Autre',
        last_name: '',
        first_name: '', 
        phone_number: '',
        email: '',
        flightNumber: '',
        terminal: ''
    },
    id_company: "Tarif-vtc.fr",
  });

  // Fonction pour passer à l'étape suivante
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // Fonction pour revenir à l'étape d'avant
  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name.startsWith('options.')) {
      const optionName = name.split('.')[1]; // Récupérer le nom de l'option
        setFormData({
          ...formData,
          options: {
            ...formData.options,
            [optionName]: value, // Mettre à jour l'option correspondante
          },
        });
      } else if(name.startsWith('passengerDetails.')){
        const passengerDetailsName = name.split('.')[1];
        setFormData({
          ...formData,
          passengerDetails: {
            ...formData.passengerDetails,
            [passengerDetailsName]: value, // Mettre à jour l'option correspondante
          },
        });
      } else {
        // Si le champ modifié n'est pas un champ d'options
        setFormData({
          ...formData,
          [name]: value,
        });
      }
  };

  // Fonction pour soumettre les données du formulaire de l'étape 1
  const handleStep1Submit = (data) => {
    setFormData(data); // Enregistrer les données du formulaire de l'étape 1
    nextStep(); // Passer à l'étape suivante
  };

  // Fonction pour soumettre les données du formulaire de l'étape 2
  const handleStep2Submit = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data })); // Mettre à jour les données existantes avec les nouvelles données
    nextStep(); // Passer à l'étape suivante
  };


  const handleFormSubmit = async (data, step) => {
    if (step === 1) {
      console.log('Données de la Step 1:', data);

      const url = `${apiUrl}/createReservation/`;

      const requestBody = data;
  
      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestBody)
          });
  
          if (!response.ok) {
              throw new Error('Failed to create reservation.');
          }
  
          const data = await response.json();
          console.log('Reservation created successfully:', data);

          // Mettre à jour le state avec les données de retour
          setFormData(prevState => ({
            ...prevState,
            travelCalculateData: {
              ...prevState.travelCalculateData,
              price: data.price_travel,
              devise: data.devise,
              distance: data.distance,
              estimated_time: data.time_travel,
            },
            id_travel: data.id_travel,
            // Mettez à jour d'autres champs si nécessaire
          }));

          console.log('formData : ', formData)
          // si ok alors go etape 2
          setCurrentStep(2);

          // Faites quelque chose avec les données retournées si nécessaire
      } catch (error) {
          console.error('Error creating reservation:', error.message);
      }


    } else if (step === 2) {
      // Traitez les données soumises de la Step 2
      console.log('Données de la Step 2:', data);

    const url = `${apiUrl}/add_options/`;

      const requestBody = {
          id_travel: data.id_travel,
          nb_passenger: data.nb_passenger,
          nb_suitcase: data.nb_suitcase,
          nb_chair_child: data.options.babySeat, 
          tablet_home: data.options.tablet_home,
          nb_booster_seat: data.options.nb_booster_seat,
      };

        try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestBody)
          });
  
          if (!response.ok) {
              throw new Error('Failed to create reservation.');
          }
  
          const data = await response.json();
          console.log('Reservation created successfully:', data);

          setFormData(prevState => ({
            ...prevState,
            options: {
              ...prevState.options,
              price_option: data.price_option,
              babySeat: data.nb_chair_child,
              nb_booster_seat: data.nb_booster_seat,
              tablet_home: data.tablet_home,
            },
            nb_passenger: data.nb_passenger,
            nb_suitcase: data.nb_suitcase,
          }));

          console.log("FormData STATE : ", formData)
  
        setCurrentStep(3);
  
        // Passer à la Step suivante ou finaliser la réservation
        // Par exemple, si vous avez une Step 3, vous pouvez passer à la Step 3 ici
      }
      catch (error) {
        console.error('Error creating reservation:', error.message);
      }


    }
    else if (step === 3) {
      // Traitez les données soumises de la Step 2
      console.log('Données de la Step 3:', data);

      const url = `${apiUrl}/add_customer/`;

      const requestBody = {
          id_travel: data.id_travel,
          gender: data.passengerDetails.gender,
          last_name: data.passengerDetails.last_name,
          first_name: data.passengerDetails.first_name, 
          phone_number: data.passengerDetails.phone_number,
          email: data.passengerDetails.email,
          flightNumber: data.passengerDetails.flightNumber,
          terminal: data.passengerDetails.terminal
        };

      try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error('Failed to create reservation.');
        }

        const data = await response.json();
        console.log('Reservation created successfully:', data);

        // Mettre à jour le state avec les données de retour

      setCurrentStep(4);

      // Passer à la Step suivante ou finaliser la réservation
      // Par exemple, si vous avez une Step 3, vous pouvez passer à la Step 3 ici
    }
    catch (error) {
      console.error('Error creating reservation:', error.message);
    }
    
  }
};


  return (
    <div>
      {/* Afficher l'étape appropriée en fonction de currentStep */}
      {currentStep === 1 && <Step1 onSubmit={(data) => handleFormSubmit(data, 1)} formData={formData} setFormData={setFormData} handleChange={handleChange} />}
      {currentStep === 2 && <Step2 onSubmit={(data) => handleFormSubmit(data, 2)} formData={formData} setFormData={setFormData} handleChange={handleChange} previousStep={previousStep} />}
      {currentStep === 3 && <Step3 onSubmit={(data) => handleFormSubmit(data, 3)} formData={formData} setFormData={setFormData} handleChange={handleChange} previousStep={previousStep} />}
      {currentStep === 4 && <Step4 onSubmit={(data) => handleFormSubmit(data, 4)} formData={formData} setFormData={setFormData} handleChange={handleChange} previousStep={previousStep} />}
    </div>
  );
}

export default ReservationContainer;
