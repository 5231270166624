import React, { useState, useEffect } from 'react';
import { Outlet, Link, useParams  } from "react-router-dom";
import "./MyTravel.css"
import Accordion from './../../../components/Accordion/Accordion';

const apiUrl = process.env.NODE_ENV === 'production' 
? process.env.REACT_APP_API_URL_PRODUCTION 
: process.env.REACT_APP_API_URL_LOCAL;

export default function SuccessTravel({}) {
    const [travelData, setTravelData] = useState(null);
    const { idTravel } = useParams();

    useEffect(() => {
        if (idTravel) {
            getTravel(idTravel);
        }
    }, [idTravel]);

    const getTravel = async (idTravel) => {
       const url = `${apiUrl}/travel_id/${idTravel}/`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to create reservation.');
            }
    
            const data = await response.json();
            setTravelData(data);
    
        }
        catch (error) {
          console.error('Error creating reservation:', error.message);
        }

    }

    return (
        <>
            <div className='travel_container'>

            <div className='Travel_informations'>
                    {!travelData ? (
                        <div>Ce trajet est vide ou n'existe pas</div>
                    ) : travelData.status === "Cancel" ? (
                        <div className='cancel_travel_message'>Trajet annulé et n'est donc plus accessible !!</div>
                    ) : (
                        <div>
                            <h2>Détail du trajet</h2>

                            {!travelData.payment_status ? <p className='success_paiement_msg'> Le paiement a été effectué avec success</p> : ''}

                            <p><span className='label_data'>Prix de la course:</span> {travelData.price_travel}</p>
                            <p><span className='label_data'>Date :</span> {travelData.date}</p>
                            <p><span className='label_data'>L'heure de départ :</span> {travelData.departure_time}</p>
                            <p><span className='label_data'>Temps estimé :</span> {travelData.time_travel} minutes</p>
                            <p><span className='label_data'>Distance :</span> {travelData.distance} km</p>
                            <p><span className='label_data'>Adresse de départ :</span> {travelData.adresse_start}, {travelData.zipcode_start} {travelData.city_start}, {travelData.country_start}</p>
                            <p><span className='label_data'>Adresse d'arrivée :</span> {travelData.adresse_end}, {travelData.zipcode_end} {travelData.city_end}, {travelData.country_end}</p>

                            <Accordion title="Informations">
                                <p><span className='label_data'>Passagers :</span> {travelData.nb_passenger}</p>
                                <p><span className='label_data'>Bagages :</span> {travelData.nb_suitcase}</p>
                                <p><span className='label_data'>Siege enfant :</span> {travelData.nb_chair_child}</p>
                                <p><span className='label_data'>BoosterSeat :</span> {travelData.nb_booster_seat}</p>
                                <p><span className='label_data'>Tablette :</span> {travelData.nb_tab}</p>

                                <div className="accordion-separator"></div>

                                <h3> Passager principal : </h3>

                                <p><span className='label_data'>Prénom :</span> {travelData.first_name}</p>
                                <p><span className='label_data'>Nom :</span> {travelData.last_name}</p>
                                <p><span className='label_data'>numéro de téléphone :</span> {travelData.phone_number}</p>
                                <p><span className='label_data'>Numéro de vol :</span> {travelData.filghtNumber}</p>
                                <p><span className='label_data'>Terminal :</span> {travelData.terminal}</p>
                            </Accordion>

                            </div>
                    )}

                </div>


            </div>
        </>
      );
    };
  