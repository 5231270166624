import "./Navbar.css"

import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { HashLink as Link } from 'react-router-hash-link';

import { Outlet} from "react-router-dom";

const drawerWidth = 240;
const navItems = ['Nos services', 'Nos voitures'];


function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="drawer">
      <Link to="/#headerSection"><img src="/logo_black.png" alt="tarif vtc logo" width={150} height={100}/></Link>
      <List>

      <Link to="/#headerSection" style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="Accueil" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/#nos_services" style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="Nos services" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/#cars_list" style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="Nos voitures" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/#FAQ" style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="FAQ" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/#contact" style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="Nous contacter" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/reservation" style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="Réservez" />
            </ListItemButton>
          </ListItem>
        </Link>

      </List>

      <Box sx={{flexGrow: 0, }}>
        <a href="tel:0650240998"><Button variant="contained">06.50.24.09.98</Button></a>
      </Box>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="static" component="nav" sx={{ bgcolor: "#272727"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Link to="/#headerSection"><img src="/logo_white_crop.webp" alt="logo tarif-vtc.fr blanc" width={150}/></Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button sx={{ color: '#fff' }}>
              <Link to="/#nos_services"> Nos Services </Link>
            </Button>
            <Button sx={{ color: '#fff' }}>
              <Link to="/#cars_list"> Nos Véhicules </Link>
            </Button>
            <Button sx={{ color: '#fff' }}>
              <Link to="/#FAQ"> FAQ </Link>
            </Button>
            <Button sx={{ color: '#fff' }}>
              <Link to="/#contact"> Nous contacter </Link>
            </Button>
            <Button sx={{ color: '#fff' }}>
              <Link to="/reservation"> Réservez </Link>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, display: {marginRight: '3rem'}}}>
            <a href="tel:0650240998"><Button variant="contained">06.50.24.09.98</Button></a>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;