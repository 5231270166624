import "./NosServices.css"

import React, { useEffect } from 'react'
import { Outlet, Link } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight } from '@fortawesome/free-solid-svg-icons'




const PrestationPresentations = [
  {
    title: 'Transferts aéroport et gares',
    imagesUrl: '/vtc_gare.jpg',
    text: "Tarif-VTC, votre partenaire privilégié pour les transferts aéroport et les déplacements depuis les grandes gares de Paris. Nous vous offrons un service de transport fiable et confortable depuis les aéroports de l'Île-de-France, ainsi que les gares les plus fréquentées de la capitale. Avec notre flotte de véhicules haut de gamme, vous voyagez en toute sérénité et dans un confort optimal. Réservez dès maintenant en ligne pour vous assurer un trajet sans souci et atteindre votre destination en toute tranquillité.",
    linkUrl: '/NosServices/aeroport'
  },
  {
    title: 'Déplacements longue distance en France',
    imagesUrl: '/vtc_map.jpg',
    text: "Optez pour Tarif-VTC pour tous vos déplacements de longue distance à travers la France. Notre flotte de véhicules haut de gamme est spécialement conçue pour vous offrir un voyage confortable et sécurisé. Nos chauffeurs professionnels, courtois et expérimentés, sont là pour vous offrir un service attentionné et personnalisé. Profitez de chaque kilomètre de votre trajet en toute sérénité, en faisant confiance à Tarif-VTC pour vous conduire vers votre destination avec efficacité et tranquillité d'esprit.",
    linkUrl: '/NosServices/longuesDistances'
  },
  {
    title: 'Déplacements flexibles',
    imagesUrl: '/vtc_driver.jpg',
    text: "Profitez de la flexibilité offerte par Tarif-VTC grâce à notre service de location de chauffeur à l'heure. Que ce soit pour des déplacements urbains ou des excursions d'une journée, nous mettons à votre disposition une flotte de véhicules haut de gamme pour vous garantir un voyage sûr et serein. Contactez-nous dès maintenant pour planifier votre prochaine escapade et profitez de la commodité d'avoir un chauffeur dédié à votre disposition.",
    linkUrl: '/NosServices/miseADispo'
  },
];

const faqData = [
  {
      question: 'Quelles sont les mesures prises pour assurer un transfert en toute sécurité depuis les aéroports et les gares ?',
      answer: "Nous prenons des mesures strictes en matière de sécurité, telles que des conducteurs agréés, des véhicules bien entretenus et des protocoles de désinfection réguliers.",
  },
  {
      question: "Comment fonctionne le service de déplacements longue distance en France ? Y a-t-il des arrêts prévus en cours de route ?",
      answer: "Notre service de déplacements longue distance vous offre un trajet direct sans arrêts prévus en cours de route, pour vous garantir une arrivée rapide à votre destination.",
  },
  {
      question: "Quelles sont les options de personnalisation disponibles pour les déplacements flexibles ?",
      answer: "Vous avez la possibilité de personnaliser votre trajet en choisissant l'heure de départ, la prise en charge à domicile et en bénéficiant de retards de paiement pour une flexibilité maximale.",
  },
  {
    question: "Quelle est la procédure pour demander l'installation d'un siège enfant dans le véhicule ?",
    answer: "Il vous suffit de mentionner votre demande lors de la réservation, et nous nous assurerons de fournir un siège enfant adapté à votre trajet.",
},
{
  question: "Comment fonctionne la connexion Wi-Fi à bord des véhicules ? Est-ce qu'elle est gratuite pour les passagers ?",
  answer: "Nous offrons une connexion Wi-Fi gratuite à bord de nos véhicules, afin que vous puissiez rester connecté(e) et profiter d'Internet pendant votre trajet.",
},
{
  question: "Est-ce que vous acceptez tous les types de cartes bancaires pour le paiement à bord ?",
  answer: "Oui, nous acceptons toutes les principales cartes bancaires, vous offrant ainsi la possibilité de payer à bord par carte de manière pratique et sécurisée.",
},
];


const NosServices = () => {

  useEffect(() => {
      window.scrollTo(0, 0);
  })

  return (
    <>
          <section className="prestationSectionService">
            <div className="prestationSectionContentBottom">
              <div className='prestationBottom'>

                {PrestationPresentations.map((item, index) => (
                  <div className="prestationItem" key={item.title + index}>
                    <img src={item.imagesUrl} alt="tarif vtc" width={230} height={230}/>
                    <div className="prestationItemText">
                      <h4>{item.title}</h4>
                      <p>{item.text}</p>
                      <Link to={item.linkUrl}>Découvrir <FontAwesomeIcon icon={faCircleRight} /></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </section>

          <section className="CarMiniServicesSection">
            <div className="CarMiniServicesSectionContent">
              <h3>Ne manquez de rien à bord de nos voitures</h3>
              <div className="CarMiniServicesSectionContentRight">
                  <div className="CarMiniServicesSectionItem">
                    <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                    <p>Installation de siège enfant à la demande</p>
                  </div>
                  <div className="CarMiniServicesSectionItem">
                    <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                    <p>Bouteille d&apos;eau fournie pendant le transport</p>
                  </div>
                  <div className="CarMiniServicesSectionItem">
                    <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                    <p>Présence de chargeurs Iphone / USB type-C / Micro USB</p>
                  </div>
                  <div className="CarMiniServicesSectionItem">
                    <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                    <p>Wifi / Bluethooth</p>
                  </div>
                  <div className="CarMiniServicesSectionItem">
                    <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                    <p>Payement à bord par carte bancaire ou espèces</p>
                  </div>
              </div>
            </div>
          </section>

          <section className="FaqSection">
            <div className="FaqSectionContent">
              <div className="FaqSectionContentTitle">
                <h3>Besoin de précisions ?</h3>
                <h4>Posez-nous les bonnes questions</h4>
              </div>
              {faqData.map((item, index) => (
                  <div className="FaqSectionContentText" key={item.question + index}>
                    <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{item.answer}</Typography>
                    </AccordionDetails>
                    </Accordion>
                  </div>
              ))}
            </div>
          </section>
  </>
  );
};
export default NosServices;