import "../Services.css"
import { Outlet, Link } from "react-router-dom";
import React, { useEffect } from 'react'

import Button from '@mui/joy/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'


const ServiceMiseADispo = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })
  
    return (
        <>
            <section className="service_section">
                <div className="service_mise_a_dispo_section_contentHead">
                    <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                    <h1>Mise à disposition de chauffeur</h1>
                </div>

                <section className='head_title_section'>
                    <div className='head_title_content'>
                        <h3> Chauffeur privé en région parisienne</h3>
                        <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
                        <div className='social_media_icons'>
                        {/*<a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>*/}
                        <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                        </div>
                    </div>
                    <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un chauffeur </Button></Link></div>
                </section>  

                <div className="service_content_deck">
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                        <h3>Chauffeur professionels</h3>
                        <p>Nos chauffeurs sont sélectionnés avec soin pour leur expérience, leur savoir-faire et leur professionnalisme.</p>
                    </div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                        <h3>Flexibilité</h3>
                        <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                    </div>
                    <div className="service_deck_item">
                        <FontAwesomeIcon icon={faStar} className="iconCustom"/>
                        <h3>Excellence</h3>
                        <p>Nous mettons tout en œuvre pour offrir à nos clients une expérience de transport exceptionnelle, en veillant à chaque détail pour garantir leur satisfaction totale.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <img src='/mercedes.webp' alt="intérieur avant d'une voiture de marque mercedes noir confortable" width={500} height={300}/>
                    <div className="service_content_informations_text">
                        <h3>Service de mise à disposition flexible avec Tarif-VTC</h3>
                        <p>Avec Tarif-VTC, vous bénéficiez d&apos;un chauffeur expérimenté et professionnel qui se tient à votre disposition pendant la durée de votre choix. Notre objectif est de vous offrir un voyage sans souci et personnalisé, en veillant à ce que vous arriviez à destination en toute sécurité et à l&apos;heure. </p>
                        <p>Profitez de notre service de mise à disposition flexible pour une journée complète d&apos;exploration ou pour des déplacements ponctuels dans la ville.</p>
                    </div>
                </div>

                <div className="service_content_informations">
                    <div className="service_content_informations_text">
                        <h3>Planifiez votre prochaine excursion</h3>
                        <p>Contactez-nous dès maintenant pour planifier votre prochaine excursion avec Tarif-VTC. Nous sommes là pour vous offrir un service de qualité, une expérience de transport inégalée et une satisfaction totale. </p>
                        <p>Faites-nous confiance pour vous accompagner lors de vos déplacements et profitez d&apos;un service sur mesure adapté à vos besoins spécifiques.</p>
                    </div>
                    <img src='/mercedes2.webp' alt="chauffeur en costume gris ouvre la porte d'une mercedes noir à une cliente en costume et chaussure à talon noir devant un batiment marron" width={500} height={300}/>
                </div>
            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <p>Profitez de la commodité d'un chauffeur à votre disposition.</p>
                        <p>Réservez votre service sur mesure en un instant !</p>
                        </div>
                    <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un chauffeur</Button></Link></div>
                </div>
            </section>

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
        </>
    );
  };
  export default ServiceMiseADispo;