import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReservationProvider } from './hooks/ReservationProvider';

import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import NosServices from './pages/NosServices/NosServices';
import ServiceAeroport from './pages/NosServices/ServiceAeroport/ServiceAeroport';
import ServiceLonguesDistances from './pages/NosServices/ServiceLonguesDistances/ServiceLonguesDistances';
import ServiceMiseADispo from './pages/NosServices/ServiceMiseADispo/ServiceMiseADispo';
import APropos from './pages/Apropos/APropos';
import NosVoitures from './pages/NosVoitures/NosVoitures';
import VoituresInformations from './pages/NosVoitures/VoituresInformations';
import Contact from './pages/Contact/Contact';
import MentionsLegales from './pages/MentionsLegales/MentionsLegales';
import PolitiqueDeConfidentialite from './pages/PolitiqueDeConfidentialité/PolitiqueDeConfidentialite';
import ReservationContainer from './pages/Reservation/ReservationContainer';
import MyTravel from './pages/Reservation/MyTravel/MyTravel';
import DetailTravel from './pages/Reservation/MyTravel/DetailTravel';
import SuccessTravel from './pages/Reservation/MyTravel/SuccessTravel';

import PageErreur404 from './pages/404/PageErreur404'

import Eco from './pages/Eco/eco'

import Taxi6Places from './pages/Temporaire/taxi-6-places'
import VTCCharlesdeGaulle from './pages/Temporaire/vtcCharlesDeGaulle'
import TaxiLongueDistance from './pages/Temporaire/taxi-longue-distance'

import AddressForm from './pages/Temporaire/testForm'


/*<Route path="/NosServices"> <Route index element={<NosServices />} /> </Route>
<Route path="/NosVoitures"> <Route index element={<NosVoitures />} /> </Route>
<Route path="/Contact"> <Route index element={<Contact />} /> </Route> 

            <Route path="/montrajet/:travelId" element={<ModifyTravel />} />
*/

function App() {
  return (
    <BrowserRouter>
      <ReservationProvider>
        <Layout>
          <Routes>
            <Route path="/"> <Route index element={<Home />} /></Route>
            <Route path="/nos-services/aeroport"> <Route index element={<ServiceAeroport />} /> </Route>
            <Route path="/nos-services/longues-distances"> <Route index element={<ServiceLonguesDistances />} /> </Route>
            <Route path="/nos-services/mise-a-dispo"> <Route index element={<ServiceMiseADispo />} /> </Route>
            <Route path="/a-propos"> <Route index element={<APropos />} /> </Route>
            <Route path="/mentions-legales"> <Route index element={<MentionsLegales />} /> </Route>
            <Route path="/politique-de-confidentialite"> <Route index element={<PolitiqueDeConfidentialite />} /> </Route>
            <Route path="/nos-voitures/:carName" element={<VoituresInformations />} />
            <Route path="/reservation" element={<ReservationContainer />} />
            <Route path="/montrajet" element={<MyTravel />} />
            <Route path="/montrajet/:idTravel/success" element={<SuccessTravel />} />
            <Route path="/montrajet/:idTravel" element={<DetailTravel />} />

            <Route path='*' element={<PageErreur404 />}/>

            <Route path="/eco" element={<Eco />} />

            <Route path="/taxi-6-places" element={<Taxi6Places />} />
            <Route path="/vtc-roissy-charles-de-gaulle" element={<VTCCharlesdeGaulle />} />
            <Route path="/taxi-longue-distance" element={<TaxiLongueDistance />} />
            <Route path="/testadd" element={<AddressForm />} />
          </Routes>
        </Layout>
      </ReservationProvider>
    </BrowserRouter>
  );
}

export default App;
