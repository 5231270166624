import React, { useState } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import "./Step1.css";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const steps = [
  'Itinéraire',
  'Options',
  'Passager',
  'Paiement',
];

const libraries = ['places'];

export default function Step1({ onSubmit, formData, setFormData, handleChange }) {
  const [autocompleteStart, setAutocompleteStart] = useState(null);
  const [autocompleteEnd, setAutocompleteEnd] = useState(null);
  const [open, setOpen] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBDZl1s5PQ6TREMrz-OmiXLu2dDtIGkm8Q', // Remplacez par votre clé API Google Maps
    libraries,
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (formData.type_travel === 'simple journey') {
      if (formData.adresse_start && formData.adresse_end) {
        onSubmit(formData);
      } else {
        setOpen(true);
        console.log('Une erreur est survenue lors de la saisie des données');
      }
    } else if (formData.type_travel === 'miseAdispo') {
      if (formData.adresse_start) {
        onSubmit(formData);
      } else {
        setOpen(true);
        console.log('Une erreur est survenue lors de la saisie des données');
      }
    }
  };

  const today = new Date().toISOString().split('T')[0];

  const addHours = (date, hours) => {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours-1}:${minutes}`;
  };

  const now = new Date();
  const minTime = formatTime(addHours(now, 2));

  const handlePlaceChanged = (field, autocomplete) => () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
  
      let streetNumber = '';
      let streetName = '';
      let city = '';
      let postalCode = '';
      let country = '';
  
      addressComponents.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name;
        }
        if (component.types.includes('route')) {
          streetName = component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
  
      const formattedAddress = `${streetNumber} ${streetName}, ${postalCode} ${city}, ${country}`;
  
      setFormData(prevData => ({
        ...prevData,
        [field]: formattedAddress,
        [field.includes('start') ? 'city_start' : 'city_end']: city,
        [field.includes('start') ? 'zipcode_start' : 'zipcode_end']: postalCode,
        [field.includes('start') ? 'country_start' : 'country_end']: country,
      }));
    }
  };
  
  
  const onLoadStart = (autocompleteInstance) => {
    setAutocompleteStart(autocompleteInstance);
  };

  const onLoadEnd = (autocompleteInstance) => {
    setAutocompleteEnd(autocompleteInstance);
  };

  /* NE PAS SUPPRIMER / LABEL MISE A DISPO
              <label className={formData.type_travel === 'miseAdispo' ? 'active' : ''}>
              <input
                type="radio"
                value="miseAdispo"
                checked={formData.type_travel === 'miseAdispo'}
                onChange={handleChange}
                name='type_travel'
              />
              Mise à disposition
            </label>
  */

  return isLoaded ? (
    <>
      <Box sx={{ width: '100%' }} className="stepper">
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {open && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
            Une erreur est survenue avec le formulaire
          </Alert>
        </Snackbar>
      )}

      <div className='FormContent'>
        <form onSubmit={handleFormSubmit}>
          <div className='FormTravelType'>
            <label className={formData.type_travel === 'simple journey' ? 'active' : ''}>
              <input
                type="radio"
                value="simple journey"
                checked={formData.type_travel === 'simple journey'}
                onChange={handleChange}
                name='type_travel'
              />
              Trajet Simple
            </label>

          </div>

          <div className='FormLocation          '>
            <div className='FormLocationDeparture'>
              <label>Départ</label>
              <Autocomplete onLoad={onLoadStart} onPlaceChanged={handlePlaceChanged('adresse_start', autocompleteStart)}>
                <TextField
                  id="outlined-basic"
                  label="Adresse de départ"
                  variant="outlined"
                  name="adresse_start"
                  value={formData.adresse_start}
                  onChange={handleChange}
                  className='textFieldDeparture'
                />
              </Autocomplete>
            </div>

            {formData.type_travel === 'simple journey' ? (
              <div className='FormLocationArrival'>
                <label>Destination</label>
                <Autocomplete onLoad={onLoadEnd} onPlaceChanged={handlePlaceChanged('adresse_end', autocompleteEnd)}>
                  <TextField
                    id="outlined-basic"
                    label="Adresse d'arrivée"
                    variant="outlined"
                    name="adresse_end"
                    value={formData.adresse_end}
                    onChange={handleChange}
                    className='textFieldArrival'
                  />
                </Autocomplete>
              </div>
            ) : (
              <div className='FormDuration'>
                <label>Durée</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.duration}
                  label="duration"
                  name="duration"
                  onChange={handleChange}
                  className='DurationSelect'
                >
                  <MenuItem value={3}>3 Heures</MenuItem>
                  <MenuItem value={4}>4 Heures</MenuItem>
                  <MenuItem value={5}>5 Heures</MenuItem>
                  <MenuItem value={6}>6 Heures</MenuItem>
                  <MenuItem value={7}>7 Heures</MenuItem>
                  <MenuItem value={8}>8 Heures</MenuItem>
                  <MenuItem value={9}>9 Heures</MenuItem>
                  <MenuItem value={10}>10 Heures</MenuItem>
                </Select>
              </div>
            )}
          </div>

          <div className='FormData'>
            <div className='FormDataDateTime'>
              <div className='FormDataDate'>
                <label>Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  min={today}
                />
              </div>
              <div className='FormDataTime'>
                <label>Horaire de départ</label>
                <input
                  type="time"
                  name="departure_time"
                  value={formData.departure_time}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='FormDataCarType'>
              <label>Choisir une Voiture</label>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type_car"
                value={formData.type_car}
                onChange={handleChange}
              >
                <FormControlLabel value="eco" control={<Radio />} label="Economie" />
                <FormControlLabel value="businessVan" control={<Radio />} label="Business Van" />
                <FormControlLabel value="premium" control={<Radio />} label="Premium" />
                <FormControlLabel value="businessCar" control={<Radio />} label="Business Car" />
              </RadioGroup>
            </div>
          </div>
          <button type="submit" className='submitButton'>Suivant</button>
        </form>
      </div>
    </>
  ) : <div>Loading...</div>;
}

