import * as React from 'react';
import "./Step4.css"

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const steps = [
  'Itinéraire',
  'Options',
  'Passager',
  'Paiement',
];

const apiUrl = process.env.NODE_ENV === 'production' 
? process.env.REACT_APP_API_URL_PRODUCTION 
: process.env.REACT_APP_API_URL_LOCAL;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Step3({ onSubmit, formData, setFormData, handleChange, previousStep }) {

  const baseURL = window.location.origin;


  const handleFormSubmit = async (event) => {
    event.preventDefault();

    onSubmit(formData);
  }

  const payTravel = async () => {
   const createPaymentUrl = `${apiUrl}/create-payment-session/`;

    if (!formData) {
        console.error('Travel data is not available.');
        return;
    }

    try {
        // Créer une session de paiement
        const paymentResponse = await fetch(createPaymentUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (!paymentResponse.ok) {
            throw new Error('Failed to create payment session.');
        }

        const paymentSession = await paymentResponse.json();

        // Rediriger l'utilisateur vers la page de paiement de Stripe
        window.location.href = paymentSession.url;

    } catch (error) {
        console.error('Error in payTravel:', error.message);
    }

}

const payTravelInCar = async () => {
  const createPaymentUrl = `${apiUrl}/send_to_athena/`;

   if (!formData) {
       console.error('Travel data is not available.');
       return;
   }

   try {
       // Créer une session de paiement
       const paymentResponse = await fetch(createPaymentUrl, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(formData)
       });

       if (!paymentResponse.ok) {
           throw new Error('Failed to create payment session.');
       }

       const paymentSession = await paymentResponse.json();

       // Rediriger l'utilisateur vers la page de paiement de Stripe
       window.location.href = `${baseURL}/montrajet/${formData.id_travel}`;

   } catch (error) {
       console.error('Error in payTravel:', error.message);
   }
  }


    return (
        <>

          <Box sx={{ width: '100%' }} className="stepper">
            <Stepper activeStep={3} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <div className='container'>

            <div className='FormStep4Content'>

                <h1>Méthode de paiement</h1>

                <div className='payment_card_container'>
                  <a href='#' onClick={payTravel}>
                    <div className='payment_card_online'>
                        <h2>Payez maintenant en ligne</h2>
                        <img src="/logo-stripe.png" alt="logo stripe" width={500} height={150}/>
                    </div>
                  </a>
                  <a href="#" onClick={payTravelInCar}>
                    <div className='payment_card_incar'>
                          <h2>Payez dans la voiture avec le chauffeur</h2>
                          <img src="/driver.webp" alt="driver" width={500} height={150}/>
                    </div>
                  </a>
                </div>

                <div className='navigationButtons'>
                  <button type="button" onClick={previousStep} className='previousStepButton'>Retour</button>
              </div>

            </div>

            <div className='CurrentInformations'>

            <div className='price_container_options'>
                <div className='price_inner_container'>
                  <p>Prix de la course</p>
                  <div className='price_content'>{formData.travelCalculateData.price} €</div>
                </div>
                <div className='price_options_inner_container'>
                  <p>Prix des options</p>
                  <div className='price_options_content'>{formData.options.price_option} €</div>
                </div>
                <div className='price_inner_container_total'>
                  <p>Prix Total</p>
                  <div className='price_content_total'>{parseFloat(formData.travelCalculateData.price) + parseFloat(formData.options.price_option)} €</div>
                </div>
            </div>

            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">Temps estimé :</StyledTableCell>
                        <StyledTableCell align="left">{formData.travelCalculateData.estimated_time} min</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left">Distance de la course :</StyledTableCell>
                        <StyledTableCell align="left">{formData.travelCalculateData.distance} km</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  TRAJET
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Adresse de départ :</StyledTableCell>
                        <StyledTableCell align="left">{formData.adresse_start}, {formData.zipcode_start} {formData.city_start}, {formData.country_start}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Adresse d'arrivée :</StyledTableCell>
                        <StyledTableCell align="left">{formData.adresse_end}, {formData.zipcode_end} {formData.city_end}, {formData.country_end}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Date :</StyledTableCell>
                        <StyledTableCell align="left">{formData.date}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>L'heure de départ :</StyledTableCell>
                        <StyledTableCell align="left">{formData.departure_time}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Voiture :</StyledTableCell>
                        <StyledTableCell align="left">{formData.type_car}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  PASSAGERS
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Nombre de passagers :</StyledTableCell>
                        <StyledTableCell align="left">{formData.nb_passenger}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Nombres de bagages :</StyledTableCell>
                        <StyledTableCell align="left">{formData.nb_suitcase}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  OPTIONS
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Siège bébé :</StyledTableCell>
                        <StyledTableCell align="left">{formData.options.babySeat}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left"className='label_data'>Réhausseur :</StyledTableCell>
                        <StyledTableCell align="left">{formData.options.nb_booster_seat}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>
            </div>

          </div>

        </>
      );
    };
  