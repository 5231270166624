import * as React from 'react';
import "./Step2.css"

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const steps = [
  'Itinéraire',
  'Options',
  'Passager',
  'Paiement',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Step2({ onSubmit, formData, setFormData, handleChange, previousStep }) {

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    onSubmit(formData);
  }

    return (
        <>

          <Box sx={{ width: '100%' }} className="stepper">
            <Stepper activeStep={1} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <div className='container'>

            <div className='FormStep2Content'>
              <form onSubmit={handleFormSubmit}>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Passagers</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.nb_passenger}
                  label="nb_passenger"
                  name="nb_passenger"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1 passager</MenuItem>
                  <MenuItem value={2}>2 passagers</MenuItem>
                  <MenuItem value={3}>3 passagers</MenuItem>
                  <MenuItem value={4}>4 passagers</MenuItem>
                  <MenuItem value={5}>5 passagers</MenuItem>
                  <MenuItem value={6}>6 passagers</MenuItem>
                  <MenuItem value={7}>7 passagers</MenuItem>
                </Select>
              </FormControl>
              
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Bagages</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.nb_suitcase}
                  label="nb_suitcase"
                  name="nb_suitcase"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>0 Bagages</MenuItem>
                  <MenuItem value={1}>1 Bagages</MenuItem>
                  <MenuItem value={2}>2 Bagages</MenuItem>
                  <MenuItem value={3}>3 Bagages</MenuItem>
                  <MenuItem value={4}>4 Bagages</MenuItem>
                  <MenuItem value={5}>5 Bagages</MenuItem>
                  <MenuItem value={6}>6 Bagages</MenuItem>
                  <MenuItem value={7}>7 Bagages</MenuItem>
                </Select>
              </FormControl>

              OPTIONS

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Siege Auto</InputLabel>
                <Select
                  labelId="babySeat-label"
                  id="babySeat"
                  value={formData.options.babySeat}
                  name="options.babySeat"
                  onChange={handleChange}
                  max={formData.nb_passenger}
                >
                  {[...Array(formData.nb_passenger + 1).keys()].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value} Siège{value > 1 ? 's' : ''} Auto
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Réhausseur</InputLabel>
                <Select
                  labelId="boosterSeat-label"
                  id="nb_booster_seat"
                  value={formData.options.nb_booster_seat}
                  name="options.nb_booster_seat"
                  onChange={handleChange}
                  max={formData.nb_passenger}
                >
                  {[...Array(formData.nb_passenger + 1).keys()].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value} Réhausseur{value > 1 ? 's' : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tablette</InputLabel>
                <Select
                  labelId="nb_tab-label"
                  id="nb_tab"
                  value={formData.options.tablet_home}
                  name="options.tablet_home"
                  onChange={handleChange}
                >

                      <MenuItem value={true}>Oui</MenuItem>
                      <MenuItem value={false}>Non</MenuItem>
                </Select>
              </FormControl>

              <div className='navigationButtons'>
                  <button type="button" onClick={previousStep} className='previousStepButton'>Retour</button>
                  <button type="submit" className='submitButton'>Suivant</button>
              </div>

              </form>     
            </div>

            <div className='CurrentInformations'>

              <div className='price_container'>
                  <p>Prix de la course</p>
                <div className='price_content'>{formData.travelCalculateData.price} €</div>
              </div>

              {formData.type_travel === 'miseAdispo' ? "" : (
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Temps estimé :</StyledTableCell>
                        <StyledTableCell align="left">{formData.travelCalculateData.estimated_time} min</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Distance de la course :</StyledTableCell>
                        <StyledTableCell align="left">{formData.travelCalculateData.distance} km</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              )}

              <div>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  TRAJET
                </Typography>

                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Adresse de départ :</StyledTableCell>
                        <StyledTableCell align="left">{formData.adresse_start}, {formData.zipcode_start} {formData.city_start}, {formData.country_start}</StyledTableCell>
                      </StyledTableRow>

                      {formData.type_travel === 'simple journey' ? (
                        <StyledTableRow>
                          <StyledTableCell align="left" className='label_data'>Adresse d'arrivée :</StyledTableCell>
                          <StyledTableCell align="left">{formData.adresse_end}, {formData.zipcode_end} {formData.city_end}, {formData.country_end}</StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell align="left" className='label_data'>Durée:</StyledTableCell>
                          <StyledTableCell align="left">{formData.duration} Heures</StyledTableCell>
                        </StyledTableRow>
                      )}

                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Date :</StyledTableCell>
                        <StyledTableCell align="left">{formData.date}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>L'heure de départ :</StyledTableCell>
                        <StyledTableCell align="left">{formData.departure_time}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className='label_data'>Voiture :</StyledTableCell>
                        <StyledTableCell align="left">{formData.type_car}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </div>
            </div>

        </div>


        </>
      );
    };
  