const reservationReducer = (state, action) => {
    switch (action.type) {
      case 'SET_INFO':
        return { ...state, basicInfo: action.payload };
      // Ajoutez d'autres cas selon vos besoins
      default:
        return state;
    }
  };
  
  export default reservationReducer;