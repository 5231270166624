import React, { useState, useRef, useEffect } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];
const googleMapsApiKey = 'AIzaSyBDZl1s5PQ6TREMrz-OmiXLu2dDtIGkm8Q'; // Remplacez par votre clé API Google Maps

const AddressForm = () => {
    const [address, setAddress] = useState('');
    const [addressComponents, setAddressComponents] = useState({
        street: '',
        city: '',
        postalCode: '',
        country: ''
    });

    const autocompleteRef = useRef(null);

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        const components = place.address_components;

        let street = '', city = '', postalCode = '', country = '';

        components.forEach(component => {
            const types = component.types;
            if (types.includes('route')) {
                street = component.long_name;
            }
            if (types.includes('locality')) {
                city = component.long_name;
            }
            if (types.includes('postal_code')) {
                postalCode = component.long_name;
            }
            if (types.includes('country')) {
                country = component.long_name;
            }
        });

        setAddress(place.formatted_address);
        setAddressComponents({ street, city, postalCode, country });
    };

    return (
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
            <form>
                <div>
                    <label>Adresse</label>
                    <Autocomplete onLoad={ref => (autocompleteRef.current = ref)} onPlaceChanged={handlePlaceChanged}>
                        <input
                            type="text"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                            placeholder="Entrez votre adresse"
                        />
                    </Autocomplete>
                </div>
                <div>
                    <label>Rue</label>
                    <input type="text" value={addressComponents.street} readOnly />
                </div>
                <div>
                    <label>Ville</label>
                    <input type="text" value={addressComponents.city} readOnly />
                </div>
                <div>
                    <label>Code Postal</label>
                    <input type="text" value={addressComponents.postalCode} readOnly />
                </div>
                <div>
                    <label>Pays</label>
                    <input type="text" value={addressComponents.country} readOnly />
                </div>
            </form>
        </LoadScript>
    );
};

export default AddressForm;
