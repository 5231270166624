import BusinessVan from "../../data/businessVan.json";
import LuxuryCar from "../../data/luxuryCar.json";
import BusinessCar from "../../data/businessCar.json";
import EcoCar from "../../data/ecoCar.json";

import './VoituresInformations.css';

import React, { useEffect, useState } from 'react'
import { Outlet, Link, useParams  } from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faLocationDot, faMoneyBill, faCar } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/joy/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';


const carDataMap = {
    businessvan: BusinessVan,
    luxurycar: LuxuryCar,
    businesscar: BusinessCar,
    ecoCar: EcoCar,
  };

const VoituresInformations = ({}) => {
    const { carName } = useParams();
    const carData = carDataMap[carName];

    const otherCars = Object.entries(carDataMap).filter(([key, car]) => car.name !== carData.name);

    useEffect(() => {
      window.scrollTo(0, 0);
    })
  
    if (!carData) {
      // Gérez le cas où les données de la voiture ne sont pas trouvées
      return <div>Voiture non trouvée</div>;
    }

    return (
        <div className="car_section">
          <div className="nos_voitures_section_contentHead" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${carData.wallpaper})`}}>
            <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
            <h1>{carData.marque} {carData.model}</h1>
          </div>

          <section className='head_title_section'>
              <div className='head_title_content'>
                <h3> Chauffeur privé en région parisienne</h3>
                <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
                <div className='social_media_icons'>
                  <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                  <a href="https://www.instagram.com/drivigo/"><img src="/instagram_logo.webp" alt="tarif vtc instagram"/></a>
                </div>
              </div>
              <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez </Button></Link></div>
            </section>    

            <div className="car_details">
              <div className="car_details_secondary">
                  <div className="car_details_travel">
                      <h3>TRAJET</h3>
                        <p><GroupIcon sx={{ fontSize: 30 }}/> {carData.person} Personnes</p>
                        <p><LuggageIcon sx={{ fontSize: 30 }}/> {carData.luggage} Baggages</p>
                        {carData.additional_luggage ? <p><LuggageIcon sx={{ fontSize: 30 }}/> {carData.additional_luggage} Petites valises</p> : ''}
                  </div>
                  <div className="car_details_left">
                      <h3>ÉQUIPEMENTS</h3>
                      <ul>
                          {carData.equipements.map((equipement, index) => (
                              <li key={index}><ListItemIcon> <ArrowRightIcon /> </ListItemIcon> {equipement}</li>
                          ))}
                      </ul>
                  </div>
                  <div className="car_details_right">
                      <h3>CARACTÉRISTIQUES TECHNIQUES</h3>
                      <ul>
                          {carData.technical_caract.map((technical_caract, index) => (
                              <li key={index}><ListItemIcon> <ArrowRightIcon /> </ListItemIcon> {technical_caract}</li>
                              ))}
                      </ul>
                  </div>
              </div>
            </div>


            <div className="car_gallery">
                {carData.gallery.map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index}`} width={350} />
                ))}
            </div>

            <div className="other_car">
              <p>Retrouvez nos voitures : </p>
              <div className="other_car_button_list">
                {otherCars.map((car, index) => (
                  <Link to={car[1].linkUrl} key={index}><Button variant="soft"> {car[1].name} </Button></Link>
                ))}
                </div>
            </div>


            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                    <p>Voyagez avec élégance grâce à notre service VTC premium.</p>
                    <p>Réservez votre expérience de première classe en un clic !</p>
                        </div>
                    <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez </Button></Link></div>
                </div>
            </section>

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
            
        </div>
    );
};
export default VoituresInformations;

