import React, { createContext, useContext, useReducer } from 'react';
import reservationReducer from './reservationReducer';

const ReservationContext = createContext();

export const ReservationProvider = ({ children }) => {
  const [reservationData, dispatch] = useReducer(reservationReducer, {});

  return (
    <ReservationContext.Provider value={{ reservationData, dispatch }}>
      {children}
    </ReservationContext.Provider>
  );
};

export const useReservation = () => {
  const context = useContext(ReservationContext);
  if (!context) {
    throw new Error('useReservation doit être utilisé dans un composant englobé dans ReservationProvider');
  }
  return context;
};