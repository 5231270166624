import { Outlet, Link } from "react-router-dom";
import Button from '@mui/joy/Button';
import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faStar, faBriefcase, faShield, faGear, faLeaf, faRoad } from '@fortawesome/free-solid-svg-icons'


const ServiceLonguesDistances = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })
  
    return (
        <>
            <section className="service_section">
                    <div className="service_longues_distance_section_contentHead">
                        <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                        <h1>Déplacements longue distance en France</h1>
                    </div>

                    <section className='head_title_section'>
                        <div className='head_title_content'>
                            <h3> Chauffeur privé en région parisienne</h3>
                            <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
                            <div className='social_media_icons'>
                            {/*<a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>*/}
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                            </div>
                        </div>
                        <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un trajet </Button></Link></div>
                    </section>  

                    <div className="service_content_deck">
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <h3>Sécurité</h3>
                            <p>Votre sécurité est notre priorité absolue. Nous adhérons à des normes de sécurité strictes.</p>
                        </div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faLeaf} className="iconCustom"/>
                            <h3>Environnement</h3>
                            <p>Voyagez avec nous en sachant que vous faites un choix respectueux de l&apos;environnement.</p>
                        </div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                            <h3>Flexibilité</h3>
                            <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                        </div>
                    </div>

                    <div className="service_content_informations">
                        <img src='/route.webp' alt="retroviseur latéral d'une voiture noir en mouvement sur une route entouré d'arbre"/>
                        <div className="service_content_informations_text">
                            <h3>Service de transport personnalisé et sécurisé</h3>
                            <p>Nos chauffeurs professionnels sont soigneusement sélectionnés et formés pour vous offrir un service attentionné et personnalisé tout au long de votre trajet. Leur expertise de conduite et leur connaissance approfondie des routes vous permettent de vous détendre et de profiter pleinement de chaque minute de votre voyage. </p>
                            <p>Que vous voyagiez seul, en famille ou en groupe, nous nous adaptons à vos besoins et nous nous assurons que vous arrivez à destination en toute tranquillité.</p>
                        </div>
                    </div>

                    <div className="service_content_informations bottom_infos">
                        <div className="service_content_informations_text">
                            <h3>Confort, fiabilité et service d'exception garantis</h3>
                            <p>Avec Tarif-VTC, vous pouvez compter sur notre engagement envers la qualité et la satisfaction du client. Réservez dès maintenant votre déplacement longue distance en France avec nous et vivez une expérience de voyage inoubliable, où confort, fiabilité et service d&apos;exception se rencontrent.</p>
                        </div>
                        <img src='/route2.webp' alt="route de montagne proche des nuages avalant le haut de la montagne"/>
                    </div>

            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <p>Simplifiez vos déplacements longue distance.</p>
                        <p>Réservez votre trajet dès maintenant !</p>
                        </div>
                    <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un trajet</Button></Link></div>
                </div>
            </section>

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
        </>
    );
  };
  export default ServiceLonguesDistances;