import React, { useEffect, useState } from 'react'
import './PageErreur404.css'

import { Link } from "react-router-dom";

const PageErreur404 = ({}) => {

    return (
        <>
            <section className="erreur404">
                <h1>404</h1>
                <p> Désolé, La page n'existe pas !! </p>
                <p> Retounez sur la page d'<Link to="/">accueil</Link></p>
                <img src="/chauffeur_fond_vert.webp" alt="tarif vtc logo"/>
            </section>
        </>
    );
};
export default PageErreur404;
