import BusinessVan from "../../data/businessVan.json";
import LuxuryCar from "../../data/luxuryCar.json";
import BusinessCar from "../../data/businessCar.json";
import EcoCar from "../../data/ecoCar.json";

import './eco.css';

import React, { useEffect, useState } from 'react'
import { Outlet, Link, useParams  } from "react-router-dom";

import Button from '@mui/joy/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';

const carDataMap = {
    businessvan: BusinessVan,
    luxurycar: LuxuryCar,
    businesscar: BusinessCar,
    ecoCar: EcoCar,
  };

const Eco = ({}) => {
    //const { carName } = useParams();
    const carName = "ecoCar";
    const carData = carDataMap[carName];

    const otherCars = Object.entries(carDataMap).filter(([key, car]) => car.name !== carData.name);

    useEffect(() => {
      window.scrollTo(0, 0);
    })
  
    if (!carData) {
      // Gérez le cas où les données de la voiture ne sont pas trouvées
      return <div>Voiture non trouvée</div>;
    }

    return (
        <div className="car_section">
          <div className="nos_voitures_section_contentHead" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${carData.wallpaper})`}}>
            <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
            <h1 class="display-4 text-center  p-md-3"><span class="color1"> Nos trajet économiques </span></h1>
          </div>

          <section className='head_title_section'>
            <div className='head_title_content'>
              <h3> Service <strong> TARIF VTC Roissy</strong></h3>
              <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
              <div className='social_media_icons'>
                <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
              </div>
            </div>
            <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un chauffeur </Button></Link></div>
          </section>    

          <section className="eco_container">
            <div className="eco_content">
              <div className="eco_text">
                <h2>Économisez sur vos trajets sans compromettre la qualité avec Tarif-VTC.fr</h2>
                <p>Dans un monde où le transport quotidien peut devenir une source de stress financier, Tarif-VTC.fr se positionne comme votre allié idéal pour des déplacements économiques, fiables et respectueux de l'environnement. Grâce à notre service de VTC à moindre coût, nous vous offrons une alternative compétitive aux solutions de transport traditionnelles, sans faire de compromis sur la qualité ou l'efficacité.</p>
              </div>
              <img src='/prius_blue.webp' alt="voiture de luxe blanche garé devant un avion gris et rouge dans un grand hangar blanc"/>
            </div>


          <section className="reservation_link_section" id="reservation_link">
              <div className="reservation_link_content">
                  <div className="reservation_link_left">
                      <p>Profitez de la commodité d'un chauffeur à votre disposition.</p>
                      <p>Réservez votre service sur mesure en un instant !</p>
                      </div>
                  <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un chauffeur</Button></Link></div>
              </div>
          </section>

          <div className="eco_content">
              <img src='/prius_cropped.webp' alt="voiture ecologique grise vue du haut avec un toit ouvrant" className="long_img"/>
              <div className="eco_text">
                <h3>Pourquoi choisir Tarif-VTC.fr ?</h3>
                <ul>
                  <li>Des tarifs imbattables pour une qualité supérieure : <span className="not_bold"> Vous bénéficiez d'un service haut de gamme à des prix défiant toute concurrence, ce qui fait de nous le choix numéro un pour des trajets économiques et sereins.  </span> </li>
                  <li>Véhicules hybrides pour un impact écologique réduit :
                    <span className="not_bold">
                      <ul>
                        <li> <b>Réduction des émissions de CO2 :</b> Les véhicules hybrides émettent moins de gaz à effet de serre, contribuant ainsi à la préservation de notre planète.</li>
                      </ul>
                    </span>
                  </li> 
                    <span className="not_bold">
                      <ul>
                        <li> <b>Consommation de carburant réduite :</b> Grâce à une meilleure efficacité énergétique, les véhicules hybrides consomment moins de carburant, ce qui se traduit par des économies que nous répercutons directement sur nos clients.</li>
                      </ul>
                    </span>
                    <span className="not_bold">
                      <ul>
                        <li> <b>Conduite plus silencieuse et confortable :</b> Profitez de trajets plus agréables grâce à une conduite silencieuse et une réduction des vibrations.</li>
                      </ul>
                    </span>
                  <li>Une expérience solide et reconnue dans le domaine du transport :
                    <span className="not_bold"> Tarif-VTC.fr est synonyme de fiabilité et de professionnalisme. Que ce soit pour des trajets quotidiens ou des transferts vers les grands points stratégiques de la région parisienne, comme les aéroports.</span>
                  </li> 
                </ul>
              </div>
          </div>




          </section>
          
            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
            
        </div>
    );
};
export default Eco;
