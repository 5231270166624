import "../Services.css"
import Button from '@mui/joy/Button';
import { Outlet, Link } from "react-router-dom";
import React, { useEffect } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faGear, faRoad } from '@fortawesome/free-solid-svg-icons'


const ServiceAeroport = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      })
  
    return (
        <>
            <section className="service_section">

                    <div className="service_aeroport_section_contentHead">
                        <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
                        <h1>Transferts aéroport et gares</h1>
                    </div>

                    <section className='head_title_section'>
                        <div className='head_title_content'>
                            <h3> Chauffeur privé en région parisienne</h3>
                            <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
                            <div className='social_media_icons'>
                            {/*<a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>*/}
                            <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                            </div>
                        </div>
                        <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez un transfert </Button></Link></div>
                    </section>  


                    <div className="service_content_deck">
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <h3>Chauffeur professionels</h3>
                            <p>L&apos;expertise de chauffeurs professionnels pour vous accompagner lors de vos trajets.</p>
                        </div>
                        <div className="service_deck_separator"></div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                            <h3>Flexibilité</h3>
                            <p>Nous comprenons que vos horaires peuvent être variables et que vos exigences peuvent changer.</p>
                        </div>
                        <div className="service_deck_separator"></div>
                        <div className="service_deck_item">
                            <FontAwesomeIcon icon={faRoad} className="iconCustom"/>
                            <h3>Porte à porte</h3>
                            <p>Directement de votre lieu de départ à votre destination finale, en prenant en compte tous les détails de votre trajet. </p>
                        </div>
                    </div>

                    <div className="service_content_informations">
                        <img src='/aeroport2.webp' alt="voiture de luxe blanche garé devant un avion gris et rouge dans un grand hangar blanc"/>
                        <div className="service_content_informations_text">
                            <h3>Spécialiste des transferts aéroport et navettes</h3>
                            <p>Chez Tarif-VTC, nous sommes spécialisés dans les transferts aéroport et les déplacements depuis les grandes gares de Paris. Nous assurons des liaisons directes vers les principaux aéroports parisiens, à savoir Paris Aeroports : Orly, Roissy-Charles de Gaulle et Beauvais.</p>
                            <p>Que vous arriviez ou que vous partiez de l&apos;un de ces aéroports, notre service de navette aéroport de Paris vous garantit une prise en charge et une dépose en toute sécurité, avec des chauffeurs expérimentés et professionnels.</p>
                        </div>
                    </div>


                    <div className="service_content_informations bottom_infos">
                        <div className="service_content_informations_text">
                            <h3>Transferts depuis les gares parisiennes</h3>
                            <p>De plus, nous proposons également des transferts depuis les gares parisiennes, notamment la célèbre Gare de Lyon. Que vous ayez un train à prendre ou que vous veniez d&apos;arriver, notre service de transport vous permet de rejoindre votre destination finale en toute sérénité.</p>
                            <p>Avec Tarif-VTC, vous bénéficiez d&apos;un service fiable et ponctuel, adapté à vos horaires de vol ou de train. Réservez dès maintenant votre transfert aéroport ou votre trajet depuis les gares de Paris et profitez d&apos;un voyage confortable et sans souci vers votre destination.</p>
                        </div>
                        <img src='/vtc_gare.webp' alt="chauffeur privé ouvrant la porte et aider une cliente en costume à sortir d'une voiture privé mercedes"/>
                    </div>

            </section>

            <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <p>Simplifiez vos déplacements vers l'aéroport ou la gare.</p>
                        <p>Réservez votre trajet facilement dès maintenant !</p>
                        </div>
                    <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez un transfert</Button></Link></div>
                </div>
            </section>


            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>



        </>
    );
  };
  export default ServiceAeroport;