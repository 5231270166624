import "./APropos.css"
import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faBriefcase, faShield, faGear, faLeaf } from '@fortawesome/free-solid-svg-icons'

export default function APropos() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
      <>
            <section className="a_propos_section">
                <div className="a_propos_section_content">
                    <div className="a_propos_section_contentHead">
                    </div>
                    <div className="a_propos_section_contentTop">
                        <h2>Un transport de qualité supérieure</h2>
                        <p>Nous comprenons l&apos;importance de chaque voyage et nous nous efforçons de garantir une satisfaction totale à nos clients. Notre équipe de chauffeurs professionnels et courtois est soigneusement sélectionnée pour assurer votre tranquillité d&apos;esprit et vous offrir une expérience de transport agréable.</p>
                    </div>

                    <div className="a_propos_section_contentCoreTop">
                        <img src='/openDoor.webp' alt="chauffeur privé en costume noir ouvrant la porte d'une voiture vtc noir intérieur noir" width={500} height={300}/>
                        <div className="a_propos_section_contentCoreTopText">
                            <p>Chez Tarif-VTC, nous croyons en la ponctualité, la fiabilité et le respect absolu des normes de sécurité. Votre confiance est primordiale pour nous, c&apos;est pourquoi nous nous engageons à respecter les délais convenus et à vous conduire en toute sécurité à votre destination.</p>
                            <p>Faites confiance à Tarif-VTC pour tous vos besoins de transport. Nous sommes fiers de vous offrir un service professionnel, attentif et abordable, en veillant à ce que chaque trajet soit une expérience mémorable.</p>
                            <p>Réservez dès maintenant votre prochain voyage avec Tarif-VTC et découvrez la différence d&apos;un transport de qualité supérieure.</p>
                        </div>
                    </div>

                    <div className="a_propos_section_contentCoreSentence">
                        <p className="a_propos_section_contentCoreSentenceCore">&quot;Prendre soin de soi n&apos;est pas un acte égoïste. C&apos;est une condition préalable pour pouvoir prendre soin des autres.&quot;</p>
                        <p className="a_propos_section_contentCoreSentenceAuthor">- Eleanor Brownn -</p>
                    </div>

                    <div className="a_propos_section_contentCoreBottom">
                        <div className="a_propos_section_contentItem">
                            <FontAwesomeIcon icon={faStar} className="iconCustom"/>
                            <h3>Excellence</h3>
                            <p>Nous mettons tout en œuvre pour offrir à nos clients une expérience de transport exceptionnelle, en veillant à chaque détail pour garantir leur satisfaction totale.</p>
                        </div>
                        <div className="a_propos_section_contentItem">
                            <FontAwesomeIcon icon={faBriefcase} className="iconCustom"/>
                            <h3>Professionnalisme</h3>
                            <p>Le professionnalisme est au cœur de notre service. Nos chauffeurs expérimentés sont formés pour offrir un accueil chaleureux, un comportement courtois et une conduite sûre.</p>
                        </div>
                        <div className="a_propos_section_contentItem">
                            <FontAwesomeIcon icon={faShield} className="iconCustom"/>
                            <h3>Sécurité</h3>
                            <p>Votre sécurité est notre priorité absolue. Nous adhérons à des normes de sécurité strictes, en veillant à ce que nos véhicules soient régulièrement entretenus et équipés des dernières technologies de sécurité.</p>
                        </div>
                        <div className="a_propos_section_contentItem">
                            <FontAwesomeIcon icon={faGear} className="iconCustom"/>
                            <h3>Flexibilité</h3>
                            <p>Nous comprenons que vos besoins de déplacement peuvent être changeants. C&apos;est pourquoi nous offrons une grande flexibilité dans nos services. Que vous ayez besoin d&apos;un transfert aéroport, d&apos;un déplacement longue distance ou d&apos;une solution de transport sur mesure, nous sommes là pour répondre à vos exigences spécifiques.</p>
                        </div>
                        <div className="a_propos_section_contentItem">
                            <FontAwesomeIcon icon={faLeaf} className="iconCustom"/>
                            <h3>Environnement</h3>
                            <p>Nous croyons en notre responsabilité envers l&apos;environnement. C&apos;est pourquoi nous avons pris des mesures concrètes pour réduire notre empreinte carbone. Nous utilisons des véhicules écologiques et nous adoptons des pratiques durables pour contribuer à la préservation de notre planète. Voyagez avec nous en sachant que vous faites un choix respectueux de l&apos;environnement.</p>
                        </div>
                    </div>

                </div>
            </section>
      </>
    )
}