import BusinessVan from "../../data/businessVan.json";
import LuxuryCar from "../../data/luxuryCar.json";
import BusinessCar from "../../data/businessCar.json";

import '../NosVoitures/VoituresInformations.css';

import React, { useEffect, useState } from 'react'
import { Outlet, Link, useParams  } from "react-router-dom";

import Button from '@mui/joy/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GroupIcon from '@mui/icons-material/Group';
import LuggageIcon from '@mui/icons-material/Luggage';

const carDataMap = {
    businessvan: BusinessVan,
    luxurycar: LuxuryCar,
    businesscar: BusinessCar,
  };

const TaxiLongueDistance = ({}) => {
    //const { carName } = useParams();
    const carName = "luxurycar";
    const carData = carDataMap[carName];

    const otherCars = Object.entries(carDataMap).filter(([key, car]) => car.name !== carData.name);

    useEffect(() => {
      window.scrollTo(0, 0);
    })
  
    if (!carData) {
      // Gérez le cas où les données de la voiture ne sont pas trouvées
      return <div>Voiture non trouvée</div>;
    }

    return (
        <div className="car_section">
          <div className="nos_voitures_section_contentHead" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${carData.wallpaper})`}}>
            <img src="/logo_white_crop.webp" alt="tarif vtc logo"/>
            <h1 class="display-4 text-center  p-md-3"><span class="color1">TARIF VTC</span> – Prix Taxi longue distance PARIS</h1>
          </div>

          <section className='head_title_section'>
            <div className='head_title_content'>
              <h3> Service <strong> TARIF VTC Roissy</strong></h3>
              <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide Par <a href="tel:0650240998">téléphone</a></p>
              <div className='social_media_icons'>
                <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
              </div>
            </div>
          </section>    

          <section>

          <div className="partie">
            <p>
            Bientôt un voyage? Besoin d'un<strong> taxi longue distance à PARIS</strong> Vous cherchez un <strong> Chauffeur taxi longue distance</strong> Faites appel à TARIF-VTC, votre spécialiste en transport premium. Forts de notre expérience et de notre savoir-faire, nous intervenons pour toutes vos demandes de transport lors de vos évènements professionnels, séjours touristiques ou déplacements privés. En effet, nous vous apportons des solutions adaptées à tous vos besoins.
            </p>
            <p>
            Reconnus pour notre professionnalisme, nous mettons à votre disposition une large gamme de véhicules, à savoir : la gamme prestige, la gamme Van et la gamme business. Quel que soit votre budget, nous nous engageons à vous offrir une qualité de service à la hauteur de vos attentes. Pour réserver votre <strong>chauffeur privé PARIS</strong> , il suffit de faire votre demande en ligne !
            </p>
            <p>
            Soucieux de vous satisfaire, nous mettons à votre service une équipe de chauffeurs expérimentés, formés et disponibles afin de répondre au mieux aux attentes de nos clients. En effet, nous veillons à vous assurer une securité et un confort optimal lors de vos déplacements afin de vous permettre de préparer vos réunions ou effectuer vos appels téléphoniques importants sans perte de temps.
            </p>

            <h2 class="color2 mb upper"> <strong>Tarif taxi longue distance </strong> </h2>
            <p>
                <strong>PRIX Taxi PARIS BRETAGNE</strong> 546 - 729€ <br/>
                TARIF VTC LONGUE DISTANCE<br/>
                -  Prestige Berline 3 Personnes : 546,00 € <br/>
                -  Prestige Van     6 Personnes : 729,00 € <br/>
              </p>

              <p>
                <strong>DEVIS Taxi PARIS GRENOBLE </strong> 684 - 913€  <br/>
                 TARIF VTC LONG TRAJET<br/>
                -   Berline 3 Personnes : 684,00 € <br/>
                -  Prestige Van 6 Personnes : 913,00 € <br/>
              </p>

              <p>
                <strong>ESTIMATION Taxi PARIS BORDEAUX</strong><br/>
                697 - 931€ <br/>
                TARIF VTC LONGUES DISTANCES<br/>
                -  Prestige Berline 3 Personnes : 697,00 € <br/>
                -  Prestige Van 6 Personnes : 931,00 € <br/>
              </p>

            </div>

            <div className="partie">


                <h3 class="color3 mb upper" ><strong> tarif vtc </strong>taxi longue distance </h3>
                Vous avez un voyage d’affaires ou privé et vous cherchez un chauffeur à vitry-sur-seine? Sollicitez TARIF-VTC, votre professionnel spécialisé en transport de personnes à paris et grand-Paris. 
                Que ça soit pour vos courses professionnels ou personnels, faites appel à notre service et déplacez-vous en toute liberté.
                Reserver votre transfert vers l'aéroport avec tarif-vtc.fr. Si vous ne souhaitez pas déchiffrer les plans des transports en commun ou marchander avec les taxis locaux, tarif-vtc vous offre un service qui vous emmènera à votre destination directement depuis l’aéroport. 
                Nos chauffeurs professionnels peuvent <b>suivre votre vol</b> et ajuster l’heure de prise en charge en cas de retard de votre avion. 
                <b>un service d'accueil</b> personnalisé à l’arrivée,
                et <b>une heure d'attente gratuite</b> si vous êtes en retard, Nos chauffeurs ont une parfaite connaissance des routes locales, n’hésitez pas à leur demander conseil à propos de vos activités lors de votre séjour.<br/>
                <a href="https://www.tarif-vtc.fr/navette/" title="PRIX: TARIF DES TAXIS PAR DÉPARTEMENT"><b>TARIF DES TAXIS PAR DÉPARTEMENT </b></a>  dès mintenant,<br/> 
                <li>Tarif-vtc est un service 24h/7 </li>
                <li> Des prix tout compris confirmés à l’avance</li>

                <h3 class="color3 mb upper" >RÉSERVATION  <strong>taxi EN GARE longues distances</strong>  </h3>
                    Notre société specialisé de chauffeur privé avec des véhicule Mercedes, vous propose 3 GAMMES de véhicules de Classe Supérieur et uniquement de marque Mercedes-benz en parfait état de moins de 4 ans, avec chauffeur à des prix abordables <br/>
                    <p>
                    <li> <b>BUSINESS</b> : MERCEDES-BENZ CLASSE C </li>
                    Une mobilité efficace des dirigeants et une conduite souple qui leur permet de préparer leur réunion en toute sérénité.
                    Tarif-vtc veille à garantir votre sécurité et confort tout au long de vos déplacements.
                    </p>

                    <p>
                    <li><b> PRESTIGE </b>: MERCEDES-BENZ CLASSE E </li>
                    Véhicule prestigieux d'une elegance et des aménagements intérieurs de standing pour un transport de qualité de 3 passagers.
                    Tarif-vtc s'assure à garantir un standing sans égale tout au long de vos déplacements.
                    </p>

                    <p>
                    <li> <b>VAN </b>:  MERCEDES-BENZ CLASSE V </li>
                    Notre mission première est de transporter des personnes, de  garantir votre sécurité au maximum,
                    Tarif-vtc à specialement selectionné pour vous des fonctions inédites dans la nouvelelle Mercedes-Benz Classe V.
                    Nous souhaitons en effet satisfaire au maximum nos passagers dits « VIP » transportés par nos chauffeurs professionnels 
                    assurant des <b>prestations premiums</b>
                    </p>
            </div>

            <div className="partie">
                <p>
                    Notre offre de transport avec chauffeurs toutes distances peut être planifier à l’heure, à la journée, à la semaine ou au mois.<br/>
                    n’hésitez pas à <strong>Commander un taxi longue distance </strong> au numero <strong>06.50.24.09.09</strong>.
                </p>


                <h3 class="color3 mb upper" >Devis <strong>taxi longue distance</strong></h3>

                Nous mettons notre expérience et notre savoir-faire au service de nos clients. En effet, nous mettons à votre disposition une
                large gamme de véhicules Mercedes-Benz de différentes modeles afin de répondre à tous les goûts et à tous les budgets.
                Que ça soit pour vos courses professionnels ou personnels, faites appel à notre service et déplacez-vous en toute liberté.
                Reserver votre trajet longue distance, des Transferts longue distance vers toute la France , au départ ou a l'arrivé de la région <strong> de Bretagne ou de Paris </strong>et des villes a proximité ( Rouen, Nantes, Bordeaux, Amiens, Rouen, Normandie, Reims, Lyon, Nice, Le Mans)<br/>
                Vous avez besoin d'un taxi privé pour un déplacement long trajet ou un circuit en France?
                Une réservation ? un départ a l’immédiat ? <br/>

                <li>Siège bébé, Rehausseur </li>
                <li>Eau,bonbon, chargeurs de téléphones, Wi-fi, Bluetooth, </li>
                <li>Chauffeurs expérimentés bilingues </li>
                <li>paiement cb à bord</li>
            </div>

            <div className="partie">
            <h3 class="color3 mb upper"><strong>CHAUFFEUR PRIVE </strong> long trajet </h3>
                <p> 
                TARIF VTC accompagne sa clientèle dans l’organisation de tous types d’évènements, tels que : la Fashion Week, les dîners de gala et bien d’autres. Pour ce faire, nous proposons un service de transports haut de gamme, discret et professionnel pour des soirées réussies.</p>

                <p>Profitez d’un service d’accueil personnalisé à votre entreprise,  de transfert et de mise à disposition toute la journée et toute la nuit avec nos chauffeurs expérimentés.</p>

                <p> Qu’il s’agisse d’un évènement public ou privé, culturel ou sportif, TARIF-VTC assure le transport pour les personnes qui vous sont importants et met à leur service des chauffeurs privés, sérieux et expérimentés. </p>

                <li>Mise à disposition à l'heure, ou à la journée </li>
                <li>Garde du corps (APR) en option</li>
                <li>Chauffeurs expérimentés bilingues </li>
                <li>accueil personnalisé</li>
            </div>

          </section>

          

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>
            
        </div>
    );
};
export default TaxiLongueDistance;
